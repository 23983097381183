@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

@layer base {
  :root {
    --color-primary: #a603c3; /* Main Primary color */
    --color-primary-light: #e3a5ee;
    --color-primary-2: #b839ce;
    --color-primary-3: #640275;
    --color-primary-4: #19001e;

    --color-secondary-1-0: #ff4500; /* Main Secondary color (1) */
    --color-secondary-1-1: #ffc5af;
    --color-secondary-1-2: #ff7542;
    --color-secondary-1-3: #b23000;
    --color-secondary-1-4: #2d0c00;

    --color-secondary: #1033c7; /* Main Secondary color (2) */
    --color-secondary-light: #adbaef;
    --color-secondary-2-2: #4964d2;
    --color-secondary-2-3: #091f79;
    --color-secondary-2-4: #01071f;
  }
}

html,
body {
  width: 100%;
  padding: 0;
  margin: 0;
}

#__next {
  width: 100%;
}

h1 {
  font-size: 2rem;
  margin: 0;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.1rem;
}

h2 {
  font-size: 1.8rem;
  font-weight: 300;
  letter-spacing: 0.1rem;
}

h3 {
  font-size: 1.5rem;
  font-weight: 400;
}

h4 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0rem;
  margin-top: 0rem;
}

p {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1.1rem;
  }
}
